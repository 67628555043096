import Image from "next/legacy/image";
import tw, { styled } from "twin.macro";

export default function SecuredBy() {
    return (
        <TransactionsContainer>
            <TextTransactions>Transactions secured by:</TextTransactions>
            <TransactionLogosContainer>
                <TransactionLogo>
                    <Image unoptimized src="https://delforno.s3.af-south-1.amazonaws.com/mastercard.png" alt="Mastercard" height={38} width={52} />
                </TransactionLogo>
                <TransactionLogo>
                    <Image unoptimized src="https://delforno.s3.af-south-1.amazonaws.com/mastercard-verified.png" alt="Verified by Mastercard" height={38} width={77} />
                </TransactionLogo>
                <TransactionLogo>
                    <Image unoptimized src="https://delforno.s3.af-south-1.amazonaws.com/visa.png" alt="Visa" height={38} width={56}/>
                </TransactionLogo>
                <TransactionLogo>
                    <Image unoptimized src="https://delforno.s3.af-south-1.amazonaws.com/visa-verified.png" alt="Verified by Visa" height={38} width={65} />
                </TransactionLogo>
                <TransactionLogo>
                    <Image unoptimized src="https://delforno.s3.af-south-1.amazonaws.com/dpo.png" alt="DPO Paygate" height={38} width={89} />
                </TransactionLogo>
            </TransactionLogosContainer>
        </TransactionsContainer>
    )
}

const TextTransactions = styled.div`
    ${tw`text-xs text-white pt-1 pb-1`}
`;

const TransactionLogo = styled.div`
    ${tw`max-h-4 mr-[2px]`}
`;

const TransactionLogosContainer = styled.div`
    ${tw`flex flex-row`}
`;

const TransactionsContainer = styled.div`
    ${tw`flex flex-col items-center p-2 bg-black pb-8`}
`;
