import tw, { styled } from "twin.macro";

export default function Disclaimer() {
    return (
        <Container>
            <TextDisclaimer>
                <ListItem>Actual food products may vary. All food pictures are for illustrative purposes only and may not necessarily represent the dishes on promotion.</ListItem>
                <ListItem>Pricing may vary per region.</ListItem>
            </TextDisclaimer>
        </Container>
    )
}

const TextDisclaimer = styled.div`
    ${tw`text-grey-dark text-xs`}
`;

const Container = styled.div`
    ${tw`w-screen px-4 pt-1`}
`;

const ListItem = styled.div`
    ${tw`ml-0 mb-1`}
`;
