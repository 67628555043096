import { useEffect, useState } from "react";
import Link from "next/link";
import { useDoc } from "use-pouchdb";
import Slider from "react-slick";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import tw, { styled } from "twin.macro";
import _ from "lodash";

export default function Specials() {

    const [store, setStore] = useState("");
    const [specials, setSpecials] = useState([]);
    //const { doc: categories, loading: categoriesLoading } = useDoc('categories');
    const { doc: customer } = useDoc('customer');
    const { doc } = useDoc('menuspecials');

    async function fetchServerTime() {
        try {
            const response = await fetch(process.env.NEXT_PUBLIC_API_URL + '/api/time/current-time');
            const data = await response.json();
            return new Date(data.serverTime);
        } catch (error) {
            console.error('Error fetching server time:', error);
            return null;
        }
    }

    function convertToLocalTime(date, offset) {
        const utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        const localDate = new Date(utc + (3600000 * offset));
        return localDate;
    }

    useEffect(() => {
        const fetchAndFilterSpecials = async () => {
            if (customer && doc) {
                // Filter for day specials and store
                let storeId = 0;

                if (customer?.store?.storeId) {
                    storeId = customer.store.storeId;
                    setStore(customer.store.storeName);
                }

                const serverTime = await fetchServerTime();
                if (!serverTime) {
                    console.log('Unable to fetch server time');
                    return;
                }

                const localTime = convertToLocalTime(serverTime, 2);
                const currentDay = localTime.getDay();

                const isSpecialApplicable = (special) => {
                    // Check if the special is available on the current day
                    const daysAvailable = special.weekDay.split(',').map(Number);
                    if (!daysAvailable.includes(currentDay)) {
                        return false; // Special does not apply on the current day
                    }

                    // If startTime and endTime are defined, check if the current time is within the range
                    if (special.startTime && special.endTime) {
                        const [startHours, startMinutes] = special.startTime.split(':').map(Number);
                        const [endHours, endMinutes] = special.endTime.split(':').map(Number);
                        const startTime = new Date(localTime);
                        startTime.setHours(startHours, startMinutes, 0, 0);
                        const endTime = new Date(localTime);
                        endTime.setHours(endHours, endMinutes, 0, 0);
                        if (!(localTime >= startTime && localTime <= endTime)) {
                            return false; // Special does not apply at the current time
                        }
                    }

                    return true; // Special is applicable
                };

                let filteredSpecials = [];

                _.each(doc.menuspecials, (special) => {
                    if (isSpecialApplicable(special)) {
                        // Check if the special already exists in filteredSpecials
                        const exists = _.some(filteredSpecials, { menuSpecialId: special.menuSpecialId });
                        if (!exists) {
                            filteredSpecials.push(special);
                        }
                    }
                });

                _.each(filteredSpecials, (special) => {
                    if (special.isStoreSpecial && storeId !== 0) { // Checking if this is a store-only special
                        let _stores = special.stores.split(',').map(Number);
                        if (_stores.indexOf(storeId) === -1) {
                            _.remove(filteredSpecials, { menuSpecialId: special.menuSpecialId });
                        }
                    }
                });

                setSpecials(filteredSpecials);
            }
        };

        fetchAndFilterSpecials();
    }, [customer, doc]);

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Arrow
                style={{ right: 6, zIndex: 10 }}
                onClick={onClick}
            >
                <HiChevronRight size={30} color={'#fff'} />
            </Arrow>
        );
    }

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <Arrow
                style={{ left: 6, zIndex: 10 }}
                onClick={onClick}
            >
                <HiChevronLeft size={30} color={'#fff'} />
            </Arrow>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
            // ,
            // {
            //     breakpoint: 2400,
            //     settings: {
            //         slidesToShow: 3,
            //         slidesToScroll: 1,
            //         infinite: true,
            //         dots: false
            //     }
            // }
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return (
        <SpecialsContainer>
            <TextHeader>
                Today&apos;s Specials
            </TextHeader>
            <Slider {...settings}>
                {
                    specials.map((special, index) => {
                        let link = '/special/' + special.slug;
                        let image = "https://delforno.s3.af-south-1.amazonaws.com/special-" + special.menuSpecialId + ".jpg?t=2";

                        if (special.menuSpecialId === 999) {
                            return (
                                <SpecialContainer key={index} >
                                    <SpecialItem style={{ backgroundImage: "url('" + image + "')", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', zIndex: 2 }}>
                                        <TextStore dangerouslySetInnerHTML={{ __html: (store ? store + ' only!' : '') }} />
                                    </SpecialItem>
                                </SpecialContainer>
                            );
                        }

                        return (
                            <SpecialContainer key={index} >
                                <Link href={link} style={{ textDecoration: 'none' }}>
                                    <SpecialItem style={{ backgroundImage: "url('" + image + "')", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', zIndex: 2 }}>
                                        {/* <TextLead dangerouslySetInnerHTML={{__html: special.leadtxt}} /> */}
                                        <TextBody dangerouslySetInnerHTML={{ __html: (special.menuSpecialId === 103 ? 'R' + special.price : '') }} />
                                        {/* <TextPrice dangerouslySetInnerHTML={{__html: special.pricetxt}} /> */}
                                    </SpecialItem>
                                </Link>
                            </SpecialContainer>
                        );
                    })
                }
            </Slider>
        </SpecialsContainer>
    );
}

const Arrow = styled.div`
    ${tw`w-10 h-10 z-[100] top-[130px] bg-black rounded-md absolute block flex flex-row justify-center items-center`}
`;

const SpecialItem = styled.div`
    ${tw`w-full h-[280px] sm:bg-auto bg-contain rounded-lg relative`}
`;

const TextHeader = styled.div`
    ${tw`text-xl font-semibold tracking-wide pl-4 pt-2`}
`;

const TextBody = styled.div`
    ${tw`sm:text-6xl text-6xl text-white font-roboto absolute top-16 right-10`}
`;

const TextStore = styled.div`
    ${tw`text-xl text-white font-roboto absolute top-16 left-1/2 transform -translate-x-1/2 uppercase whitespace-nowrap`}
`;

const SpecialContainer = styled.div`
    ${tw`w-full p-2 pl-4 pr-4 relative`}
`;

const SpecialsContainer = styled.div`
    ${tw`w-full bg-white`}
`