import { useState } from "react";
import Link from "next/link";
import Head from "next/head";
import { useRouter } from "next/router";
import _ from "lodash";
import { useDoc } from "use-pouchdb";
import MenuLoader from "./../components/menuloader.js";
import SecuredBy from "./../components/securedby.js";
import Specials from "./../components/menu/specials.js";
import Disclaimer from "./../components/shared/disclaimer.js";
import tw, { styled } from "twin.macro";

export async function getServerSideProps({ req }) {
    const agents = ["googlebot", "google"];
    const agent = req.headers["user-agent"];
    const isAgent = agents.some((name) => agent.toLowerCase().includes(name.toLowerCase()));
    return {
        props: {
            isAgent,
        },
    };
}

export default function Home(props) {

    const router = useRouter();
    const { doc: categories, loading: loadingCategories, errorCategories } = useDoc('categories');
    const { doc: customer, loading: loadingCustomer, errorCustomer } = useDoc('customer');
    const [calledPush, setCalledPush] = useState(false);

    if (!loadingCustomer && !errorCustomer && customer) {
        if (!customer.isCollection && !customer.isDelivery && !calledPush && !props.isAgent) {
            router.push('/address');
            setCalledPush(true);
        }
    }

    return <>
        <Head>
            <title>Del Forno - Pizza, Pasta, Grill - Online Ordering</title>
            <meta name="title" content="Del Forno - Pizza, Pasta, Grill - Online Ordering" />
            <meta name="description" content="Order your favourite Del Forno meal online now." />
            <meta property='og:type' content='website' />
            <meta property='og:title' content="Del Forno - Pizza, Pasta, Grill - Online Ordering" />
            <meta property='og:description' content="Order your favourite Del Forno meal online now." />
            <meta property='og:site_name' content='Del Forno Ordering' />
            <meta property='og:url' content='https://order.delforno.co.za' />
        </Head>
        <MenuLoader />
        <MenuContainer style={{ backgroundImage: "url('/imgs/bg.png')", backgroundPosition: 'center', backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
            {/* <EasterContainer>
                <img src="https://delforno.s3.af-south-1.amazonaws.com/easterhunt.png" alt="Easter" />
            </EasterContainer> */}
            <Spacer />
            <Specials />
            <MenuItemsContainer>
                {
                    (!loadingCategories && !errorCategories && categories) ?
                        categories.menucategories.map((item, index) => {
                            let link = '/' + item.slug;
                            let image = "https://delforno.s3.af-south-1.amazonaws.com/" + item.slug + ".jpg";
                            return (
                                <Link key={index} href={link} style={{ textDecoration: 'none' }} legacyBehavior>
                                    <MenuItemContainer>
                                        <MenuItem style={{ backgroundImage: "url('" + image + "')", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                            <CategoryContainer>
                                                <TextCategory>
                                                    {item.category.toUpperCase()}
                                                </TextCategory>
                                            </CategoryContainer>
                                        </MenuItem>
                                    </MenuItemContainer>
                                </Link>
                            );
                        }) : <></>
                }
            </MenuItemsContainer>
            <Disclaimer />
            <div style={{ height: 20 }}></div>
            <SecuredBy />
            <div style={{ height: 50 }}></div>
        </MenuContainer>
        {/* <ModalNotifications open={openNotifications} handleClose={() => { setOpenNotifications(false) }} /> */}
    </>;
}

const TextCategory = styled.div`
    ${tw`text-white text-xl font-medium`}
`;

const CategoryContainer = styled.div`
    ${tw`absolute bg-black bg-opacity-60 p-2 bottom-4`}
`;

const MenuItemContainer = styled.div`
    ${tw`w-full md:w-[350px] pt-4 ml-4 mr-4 cursor-pointer`}
`;

const Spacer = styled.div`
    ${tw`mt-24 justify-center`}
`;

const EasterContainer = styled.div`
    ${tw`mt-24 justify-center`}
`;

const MenuContainer = styled.div`
    ${tw`w-full flex flex-col justify-center`}
`;

const MenuItemsContainer = styled.div`
    ${tw`flex flex-wrap justify-center`}
`;

const MenuItem = styled.div`
    ${tw`w-full md:w-[350px] h-[140px] relative overflow-hidden rounded`}
`;
