import { useState } from "react";
import { useDoc, usePouch } from "use-pouchdb";
import PouchDB from "pouchdb";
import each from "lodash/each";
import cloneDeep from "lodash/cloneDeep";

PouchDB.plugin(require('pouchdb-upsert'));
//const localDB = new PouchDB('delforno', { revs_limit: 1 });

export default function MenuLoader() {

    const db = usePouch()
    const { doc: menuitems, loading: menuitemsLoading } = useDoc('menuitems');
    const { doc: ingredients, loading: ingredientsLoading } = useDoc('ingredients');
    const { doc: menuoptions, loading: menuoptionsLoading } = useDoc('menuoptions');
    const { doc: menuspecials, loading: menuspecialsLoading } = useDoc('menuspecials');
    const { doc: baseprices, loading: baseLoading } = useDoc('baseprices');
    const { doc: baseingredients, loading: baseIngredientsLoading } = useDoc('baseingredients');
    const { doc: baseoptions, loading: baseOptionsLoading } = useDoc('baseoptions');
    const { doc: basespecials, loading: baseSpecialsLoading } = useDoc('basespecials');
    const { doc: customer, loading: customerLoading } = useDoc('customer');
    const [updated, setUpdated] = useState(false);

    const percentage = 6;

    const updateMenuItemPrices = async () => {

        const doc = cloneDeep(menuitems);
        doc.priceset = 'KZN';
        each(doc.menuitems, (item) => {
            item.priceSmall = Math.ceil(item.priceSmall + (item.priceSmall / 100 * percentage));
            item.priceLarge = Math.ceil(item.priceLarge + (item.priceLarge / 100 * percentage));
        });

        try {
            await db.put(doc);
        } catch (err) {
            if (err.name === 'conflict') {
                console.log('update conflict updateMenuItemPrices items')
            } else {
                console.error(err);
            }
        }

        const base = cloneDeep(baseprices);
        base.menuitems = menuitems.menuitems;

        try {
            await db.put(base);
        } catch (err) {
            if (err.name === 'conflict') {
                console.log('update conflict updateMenuItemPrices base')
            } else {
                console.error(err);
            }
        }
    }

    const updateIngredientPrices = async () => {

        const doc = cloneDeep(ingredients);
        doc.priceset = 'KZN';
        each(doc.ingredients, (item) => {
            item.price = Math.ceil(item.price + (item.price / 100 * percentage));
        });

        try {
            await db.put(doc);
        } catch (err) {
            if (err.name === 'conflict') {
                console.log('update conflict updateIngredientPrices items')
            } else {
                console.error(err);
            }
        }

        const base = cloneDeep(baseingredients);
        base.ingredients = ingredients.ingredients;

        try {
            await db.put(base);
        } catch (err) {
            if (err.name === 'conflict') {
                console.log('update conflict updateIngredientPrices base')
            } else {
                console.error(err) // Handle other errors.
            }
        }

    }

    const updateMenuOptionPrices = async () => {

        const doc = cloneDeep(menuoptions);
        doc.priceset = 'KZN';
        each(doc.menuoptions, (item) => {
            if (item.price > 0) {
                item.price = Math.ceil(item.price + (item.price / 100 * percentage));
            }
        });

        try {
            await db.put(doc);
        } catch (err) {
            if (err.name === 'conflict') {
                console.log('update conflict updateMenuOptionPrices items')
            } else {
                console.error(err);
            }
        }

        const base = cloneDeep(baseoptions);
        base.menuoptions = menuoptions.menuoptions;

        try {
            await db.put(base);
        } catch (err) {
            if (err.name === 'conflict') {
                console.log('update conflict updateMenuOptionPrices base')
            } else {
                console.error(err) // Handle other errors.
            }
        }

    }

    const updateMenuSpecialsPrices = async () => {

        console.log('updating specials')

        const doc = cloneDeep(menuspecials);
        doc.priceset = 'KZN';
        each(doc.menuspecials, (item) => {
            if (item.menuSpecialId === 103) {
                item.price = 189;
            }
        });

        try {
            await db.put(doc);
        } catch (err) {
            if (err.name === 'conflict') {
                console.log('update conflict updateMenuSpecialsPrices items')
            } else {
                console.error(err);
            }
        }

        const base = cloneDeep(basespecials);
        base.menuspecials = menuspecials.menuspecials;

        try {
            await db.put(base);
        } catch (err) {
            if (err.name === 'conflict') {
                console.log('update conflict updateMenuSpecialsPrices base')
            } else {
                console.error(err) // Handle other errors.
            }
        }

    }

    const processPricing = async () => {


        if (menuitems && baseprices && !updated && (menuitems?.priceset !== customer.region)) {

            if (customer.region === 'KZN') { //We're only dealing with one region currently
                updateMenuItemPrices();
            } else {

                const _menuitems = cloneDeep(menuitems);
                _menuitems.priceset = 'GP';
                _menuitems.menuitems = baseprices.menuitems;

                try {
                    await db.put(_menuitems);
                    //setMenuItemsUpdated(true);
                } catch (err) {
                    if (err.name === 'conflict') {
                        console.log('update conflict menu items')
                    } else {
                        console.error(err) // Handle other errors.
                    }
                }
            }
        }

        if (ingredients && baseingredients && !updated && (ingredients?.priceset !== customer.region)) {

            if (customer.region === 'KZN') { //We're only dealing with one region currently
                updateIngredientPrices();
            } else {

                const _ingredients = cloneDeep(ingredients);
                _ingredients.priceset = 'GP';
                _ingredients.ingredients = baseingredients.ingredients;

                try {
                    await db.put(_ingredients);
                    //setIngredientsUpdated(true);
                } catch (err) {
                    if (err.name === 'conflict') {
                        console.log('update conflict ingredients')
                    } else {
                        console.error(err) // Handle other errors.
                    }
                }
            }
        }

        if (menuoptions && baseoptions && !updated && (menuoptions?.priceset !== customer.region)) {

            if (customer.region === 'KZN') { //We're only dealing with one region currently
                updateMenuOptionPrices();
            } else {

                const _menuoptions = cloneDeep(menuoptions);
                _menuoptions.priceset = 'GP';
                _menuoptions.menuoptions = baseoptions.menuoptions;

                try {
                    await db.put(_menuoptions);
                    //setMenuOptionsUpdated(true);
                } catch (err) {
                    if (err.name === 'conflict') {
                        console.log('update conflict menuoptions')
                    } else {
                        console.error(err) // Handle other errors.
                    }
                }
            }
        }

        if (menuspecials && basespecials && !updated && (menuspecials?.priceset !== customer.region)) {

            if (customer.region === 'KZN') { //We're only dealing with one region currently
                updateMenuSpecialsPrices();
            } else {

                const _menuspecials = cloneDeep(menuspecials);
                _menuspecials.priceset = 'GP';
                _menuspecials.menuspecials = basespecials.menuspecials;

                try {
                    await db.put(_menuspecials);
                } catch (err) {
                    if (err.name === 'conflict') {
                        console.log('update conflict menuspecials')
                    } else {
                        console.error(err) // Handle other errors.
                    }
                }
            }
        }
    }

    if (!baseLoading && !baseIngredientsLoading && !baseOptionsLoading && !baseSpecialsLoading && !customerLoading && !ingredientsLoading && !menuitemsLoading && !menuoptionsLoading && !menuspecialsLoading && !updated) {
        processPricing();
        setUpdated(true);
    }

    return (
        null
    )
}